import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HomeComponent } from './home/home.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TitleService } from './title.service';


@NgModule({ declarations: [
        AppComponent,
        ErrorPageComponent,
        HomeComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule], providers: [TitleService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
  constructor(router: Router) {
    // This is intentional
 }
}
