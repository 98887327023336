import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ApplicationRoutes } from './ApplicationRoutes';
import { HomeComponent } from './home/home.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent, data: {  title: 'RSA-App' }
  },
  {
    path: ApplicationRoutes.SRPT,
    data: {  title: 'SRPT' },
    loadChildren: () => import('./masterpanel/masterpanel.module').then(m => m.MasterPanelModule)
  },
  {
    path: 'not-found',
    component: ErrorPageComponent,
    data: {
      message: 'Page not found!',
      title: 'RSA-App'
    }
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
